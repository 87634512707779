.custom-modal .modal-content {
  border-radius: 40px;
  padding: 4rem 15px;
}

.timer {
  margin-top: 10px;
}

.timer > svg {
  width: 200px;
  height: 200px;
}

.timer > svg > circle {
  fill: none;
  stroke-opacity: 0.3;
  stroke: #659ef3;
  stroke-width: 10px;
  transform-origin: center center;
  transform: rotate(-90deg);
}

.timer > svg > circle + circle {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  stroke-linecap: round;
  stroke-opacity: 1;
}

.timer.animatable > svg > circle + circle {
  transition: stroke-dashoffset 0.3s ease;
}

.timer > svg > text {
  font-size: 4rem;
  fill: #000;
}
#timeLeft {
  margin-top: 15px;
  display: block;
}

.timer > svg > text + text {
  font-size: 1.5rem;
  margin-top: 10px;
  fill: #000;
}
.record-icon a i {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  color: #fff;
  display: inline-block;
  border: 4px solid #fff;
  line-height: 45px !important;
  position: relative;
  top: 3px;
}
.modal-backdrop {
  background: #1c1c1c;
  opacity: 1 !important;
}
