@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap");

@font-face {
  font-family: "League Spartan";
  src: url("../../assets/fonts/LeagueSpartan-Thin.eot");
  src: url("../../assets/fonts/LeagueSpartan-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/LeagueSpartan-Thin.woff2") format("woff2"),
    url("../../assets/fonts/LeagueSpartan-Thin.woff") format("woff"),
    url("../../assets/fonts/LeagueSpartan-Thin.ttf") format("truetype"),
    url("../../assets/fonts/LeagueSpartan-Thin.svg#LeagueSpartan-Thin")
      format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Digital-7";
  src: url("../../assets/fonts/Digital-7.eot");
  src: url("../../assets/fonts/Digital-7.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/Digital-7.woff2") format("woff2"),
    url("../../assets/fonts/Digital-7.woff") format("woff"),
    url("../../assets/fonts/digital-7.ttf") format("truetype"),
    url("../../assets/fonts/Digital-7.svg#Digital-7") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  font-size: 14px;
}
.padding-class {
  padding: 0px 50px;
}
.main-section {
  font-family: "League Spartan";
  font-size: 14px;
  background: radial-gradient(farthest-side, #182158 0%, #030414 100%) no-repeat
    fixed 0 0;
  background: url(../../assets/Interviewimages/full-bg-2.jpg) no-repeat center;
  background-size: cover;
  background-position: 100% -70px;
  height: 110dvh;
  line-height: normal;
  display: flex;
  align-items: center;
}
a {
  text-decoration: none;
  outline: none;
}
.btn {
  border-radius: 9em;
  padding: 12px 35px !important;
  box-shadow: none !important;
  border: none !important;
}
.btn-secondary {
  background: #ffa006;
}
p,
figure,
ul,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.total-timer {
  color: #fff;
  margin-bottom: 2rem;
  font-size: 16px;
  text-align: center;
}
.total-timer span {
  display: inline-block;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  border-radius: 80px;
}
.dark-box {
  border-radius: 30px;
  padding: 1.5rem;
  background:#0b2033;
  backdrop-filter: blur(5px);
}

.dark-box h3 {
  font-size: 28px;
}
.questions-scroll {
  /* overflow: hidden; */
  height: 30dvh;
  width: 100%;
  overflow-y: scroll; /* Enable vertical scrolling */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
  position: relative;
  margin: 10px;
}
.question-icon {
  width: 100%;
  /* padding: 5em; */
}
.question-icon i {
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.li-img {
  display: flex;

  color: white;
  position: relative;
  margin-bottom: 10px;
}
.ques {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* gap: 5px; */
}

.li-img.active {
  opacity: 1; /* Show active question */
}

/* Hidden question */
.li-img.hidden {
  display: none; /* Hide hidden questions */
}
.ques h4 {
  margin-bottom: 5px; /* Spacing below question text */
}
.la-check-circle.green {
  color: green;
}
.la-times-circle {
  background-color: white;
}
.la-times-circle.red {
  color: red;
}
.que-skip {
  display: flex;
  position: relative;
}
#skip-que {
  position: absolute;
  right: -10.4em;
  top: 0;
  text-align: end;
}
.question-not-ans {
  display: flex;
  position: relative;
}
#no-ans-que {
  position: absolute;
  right: -10.3em;
  top: 0;
  text-align: end;
}
.questions-scroll li span {
  display: flex;

  width: 100%;
  font-size: 0.8em;
  gap: 1em;
}
#img-circle {
  font-size: 10px;
  width: 20px;

  display: block; /* Ensure image is block-level */
  margin-top: 5px;
}
.questions-scroll .arrow {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
}

.questions-scroll .arrow.down {
  top: auto;
  bottom: 0;
}

/**/
.fields li {
  display: flex;
  margin-top: 1rem;
  background: rgba(255, 255, 255, 0.2);
  padding: 5px;
  border-radius: 80px;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 16px;
}
.fields li img {
  width: 45px;
}
.fields li span:first-child {
  padding-left: 15px;
  line-height: normal;
}
.bottom-btns button {
  margin: 0 10px;
  width: 153px;
  border-radius: 80px;
  font-family: "League Spartan";
  background: #0b2033;
  font-weight: bold;
  font-size: larger;
}
.video-wrap {
  padding: 0;
  overflow: hidden;
  border-radius: 30px;
  background: rgb(11, 32, 51);
  backdrop-filter: blur(5px);
  position: relative;
  width: 100%;
  height: 30dvh;
}
.video-wrap video {
  width: 100%;
  height: auto;
  display: block;
}
.video-wrap i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background: #ffa006;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  text-align: center;
  font-size: 44px;
  line-height: 75px;
}

.canves-class {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 60dvw;
}
#canvas {
  transform: translateY(-50%);
  width: 75%;
  height: 250px;
  left: 0;
  right: 0;
  z-index: 0;
  margin-top: 5em;
}

.dg {
  display: none;
}

.timer > svg {
  width: 150px;
  height: 150px;
}

.timer > svg > circle {
  fill: none;
  stroke-opacity: 0.3;
  stroke: #659ef3;
  stroke-width: 10;
  transform-origin: center center;
  transform: rotate(-90deg);
}

.timer > svg > circle + circle {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  stroke-linecap: round;
  stroke-opacity: 1;
}

.timer.animatable > svg > circle + circle {
  transition: stroke-dashoffset 0.3s ease;
}

.timer > svg > text {
  font-size: 2rem;
  fill: #fff;
}

.timer > svg > text + text {
  font-size: 1rem;
}
.call-navs {
  margin-bottom: 30px;
}
.call-navs li {
  display: inline-block;
  padding: 0 5px;
}
.call-navs li a {
  display: block;
  width: 50px;
  height: 50px;
  background: #333;
  border-radius: 15px;
  color: #fff;
  font-size: 24px;
  line-height: 55px;
  background: #f9dedc;
  color: #1c1c1c;
}
.call-navs li a.active {
  background: #659ef3;
  color: #fff;
}

.remaining-timer span {
  font-family: "Digital-7";
  color: #fff;
  font-size: 25px;
  margin-top: 5px;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073,
      0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }

  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
      0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}
@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073,
      0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }

  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6,
      0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

.bottom-btns {
  position: absolute;
  bottom: -5rem !important; 
  left: 0;
  right: 0;
  margin: 0 auto;
}

.custom-modal .modal-content {
  border-radius: 40px;
  padding: 4rem 15px;
}

.timer {
  margin-top: 10px;
}

.timer > svg {
  width: 200px;
  height: 200px;
}

.timer > svg > circle {
  fill: none;
  stroke-opacity: 0.3;
  stroke: #659ef3;
  stroke-width: 10;
  transform-origin: center center;
  transform: rotate(-90deg);
}

.timer > svg > circle + circle {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  stroke-linecap: round;
  stroke-opacity: 1;
}

.timer.animatable > svg > circle + circle {
  transition: stroke-dashoffset 0.3s ease;
}

.timer > svg > text {
  font-size: 4rem;
  fill: #000;
}
#timeLeft {
  margin-top: 15px;
  display: block;
}

.timer > svg > text + text {
  font-size: 1.5rem;
  margin-top: 10px;
  fill: #000;
}
.record-icon a i {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  color: #fff;
  display: inline-block;
  border: 4px solid #fff;
  line-height: 45px !important;
  position: relative;
  top: 3px;
}
.modal-backdrop {
  background: #1c1c1c;
  opacity: 1 !important;
}

.btnname{
  width: 150;
  border: 1px solid #fff !important;
}
