/* .technology-grid {
  display: flex;
  margin: 0;
  padding: 0;
}

/* .technology-grid img {
  width: 15%;
  margin: 0;
  padding: 0;
} /

.technology-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid red;
}
.technology-image {
  width: 15%;
  margin: 0;
  padding: 0;
}
.active {
  background-color: black;
  color: white;
} */

.technologies-page {
  text-align: center;
}

.technology-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; /* Add some space between items */
  margin: 0;
  padding: 0;
}

.technology-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px; /* Fixed width */
  height: 120px; /* Fixed height */
  /* border: 2px solid red; */
  padding: 10px;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  cursor: pointer; /* Add a pointer cursor to indicate clickable items */
}

.technology-image {
  width: 100%; /* Image will take the full width of the container */
  height: auto; /* Maintain aspect ratio */
  max-width: 60px; /* Set a max-width to prevent the image from becoming too large */
  max-height: 70px; /* Set a max-height to prevent the image from becoming too large */
}

.active {
  background-color: rgb(101, 242, 88);
  color: white;
}
