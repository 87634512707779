/* src/DeafultInterview/ItOrNonIt/ItOrNonIt.css */
/* .it-or-non-it-page {
  text-align: center;
  padding: 20px;
}

.option-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.option-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background: transparent;
  color: black;
  border: 1px solid black;
}

.selected {
  background-color: #007bff;
  color: white;
} */

.it-or-non-it-page {
  text-align: center;
  padding: 20px;
}

.option-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.option-button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background: transparent;
  color: black;
  border: 1px solid black;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

.option-button.selected {
  background-color: #007bff;
  color: white;
}
