.blinking-badge {
    animation: blink 1s infinite;
  }
  .fields li{
    padding: 15px !important;
  }
  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  .noqueimg {
    right: 0em !important;
  }
  @media (max-width: 992px) {
    /* Adjust layout for tablets */
    .col-lg-4 {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .col-lg-5 {
      width: 100%;
    }
  
    .dark-box {
      margin-bottom: 20px;
    }
  
    .canves-class {
      margin-top: 20px;
    }
  
    .bottom-btns {
      flex-direction: column;
    }
  
    .bottom-btns button {
      margin-top: 10px;
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    /* Adjust layout for small tablets and large phones */
    .padding-class {
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .remaining-timer, .time-number {
      text-align: center;
    }
  
    .question-icon h3 {
      text-align: center;
    }
  
    .box-padding {
      padding: 0 15px;
    }
  }
  
  @media (max-width: 576px) {
    /* Adjust layout for mobile phones */
    #canvas {
      height: 150px !important;
      margin-top: 1em !important;
    }
    .desktp {
      display: none;
    }
    .call-navs{
      margin-bottom: 10px !important;
    }
    .mobpos{
      position: unset !important; 
    }
  
    .mobfull {
      width: 100% !important;
    }
     .main-section {
      background: url(../../assets/Interviewimages/full-bg-2.jpg) no-repeat center !important;
      background-size: cover !important;
      background-position: 50% - 0px !important;
      /* height: unset !important; */
  } 
    .mobpadding {
      padding: 0px 0px !important;
      /* background: url(http://localhost:3000/static/media/full-bg-2.bd827b1490207aa6bf4c.jpg) no-repeat center; */
    } 
    .submob{
      margin-top: 10px !important; 
      margin-bottom: 10px !important;
    }
    .questions-scroll{
      height: 10dvh !important;
    }
    .mobcanva{
      margin-top: 150px !important;
      width: 95dvw !important;
      justify-content: center !important;
    }
    .dark-box {
      padding: 0.5rem !important;
    }
    #skipButton,#nextButton,#submimob{
      width: 27% !important;
    }
    #submimob{
      padding: 13px !important;
    }
  }

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust as needed */
}

.spinner {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.popup-content p {
  font-size: x-large;
  padding-bottom: 18px;
}